<template>
  <div class="common-layout">
    <el-container>
      <el-header>
        <HeaderComp />
      </el-header>
      <el-container>
        <el-aside width="200px">
          <SidebarComp />
        </el-aside>
        <el-container>
          <el-main class="main-container">
            <router-view />
          </el-main>

          <el-footer class="c-footer">
            <h3>&copy; Orko Health Ltd.</h3>
          </el-footer>
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import HeaderComp from "@/components/common/Header";
import SidebarComp from "@/components/common/Sidebar";
export default {
  name: "AppLayout",
  components: {
    HeaderComp,
    SidebarComp,
  },
};
</script>
<style lang="scss" scoped>
.c-footer {
  h3 {
    text-align: center;
  }
}
.main-container {
  min-height: 80vh;
}
.common-layout {
  header {
    padding: 0;
    ul {
      border-bottom: 2px solid red;
    }
  }
}
</style>
<style lang="scss">
body {
  margin: 0;
}
</style>
<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800&display=swap");
* {
  font-family: "Poppins", sans-serif;
}
</style>
