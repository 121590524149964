import axios from "axios";
import store from "./store";

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

axios.interceptors.request.use(
  async function (config) {
    if (!config.url.includes("login") && !config.url.includes("refresh")) {
      await getAuthToken();
    }
    const token = store.getters["auth/getToken"];
    if (token && token != "null") {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      store.dispatch("auth/clearToken");
      window.location = "/login";
    } else {
      return Promise.reject(error);
    }
  }
);

async function getAuthToken() {
  // if the current store token expires soon
  // 4900000 = means 30 mins
  if (store.getters["auth/tokenExpiresIn"] - 4900000 <= Date.now()) {
    try {
      await store.dispatch("auth/refresh");
    } catch (error) {
      return Promise.reject(error);
    }
  }
  return;
}
