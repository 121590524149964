import axios from "axios";

const state = {
  isLoggedIn: false,
  authToken: "",
  authUser: {},
  expires_in: "",
};

const getters = {
  isLoggedIn(state) {
    return state.expires_in > Date.now() && state.authToken ? true : false;
  },
  getToken: (state) => {
    return state.authToken;
  },
  tokenExpiresIn(state) {
    return state.expires_in;
  },
  authUser: (state) => {
    return state.authUser;
  },
};

const actions = {
  login({ commit, dispatch }, form) {
    return axios.post("/auth/login", form).then((res) => {
      commit("SET_TOKEN", res.data.access_token);
      commit("SET_EXPIRES_IN", res.data.expires_in);
      dispatch("getUserProfile");
      return res;
    });
  },
  getUserProfile({ commit }) {
    return axios.post("/auth/me").then((res) => {
      commit("SET_USER", res.data);
      return res;
    });
  },
  logout({ commit }) {
    return axios.post("/auth/logout").then(() => {
      commit("REMOVE_TOKEN");
      commit("REMOVE_USER");
    });
  },
  clearToken({ commit }) {
    commit("REMOVE_TOKEN");
    commit("REMOVE_USER");
  },
  setToken({ commit }, token) {
    commit("SET_TOKEN", token);
  },
  refresh({ commit }) {
    return axios.post("/auth/refresh").then((res) => {
      commit("SET_TOKEN", res.data.access_token);
      commit("SET_EXPIRES_IN", res.data.expires_in);
    });
  },
};

const mutations = {
  SET_TOKEN(state, token) {
    state.authToken = token;
  },
  SET_USER(state, user) {
    state.authUser = user;
  },
  REMOVE_TOKEN(state) {
    state.authToken = "";
  },
  REMOVE_USER(state) {
    state.authUser = {};
  },
  SET_EXPIRES_IN(state, expires_in) {
    state.expires_in = Date.now() + expires_in * 1000; //converting to unix timestamp
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
