import { createRouter, createWebHistory } from "vue-router";
import AppLayout from "@/layouts/App.vue";
import PublicLayout from "@/layouts/Public.vue";
import store from "@/store/index";

const routes = [
  {
    path: "",
    component: PublicLayout,
    redirect: "/login",
    children: [
      {
        path: "/login",
        component: () =>
          import(/* webpackChunkName: "login" */ "../views/pages/Login.vue"),
      },
    ],
  },
  {
    path: "",
    component: AppLayout,
    meta: { auth: true },
    children: [
      {
        path: "/dashboard",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
      },
      {
        path: "/stocks",
        component: () =>
          import(/* webpackChunkName: "stocks" */ "../views/stocks/Stocks.vue"),
      },
      {
        path: "/stocks/create",
        component: () =>
          import(
            /* webpackChunkName: "stocks-create" */ "../views/stocks/CreateStock.vue"
          ),
      },
      {
        path: "/stocks/:id/edit",
        component: () =>
          import(
            /* webpackChunkName: "stocks-create" */ "../views/stocks/EditStock.vue"
          ),
      },
      {
        path: "/purchase-orders",
        component: () =>
          import(
            /* webpackChunkName: "purchase-orders" */ "../views/purchase-orders/PurchaseOrders.vue"
          ),
      },
      {
        path: "/purchase-orders/create",
        component: () =>
          import(
            /* webpackChunkName: "purchase-orders-create" */ "../views/purchase-orders/CreatePurchaseOrder.vue"
          ),
      },
      {
        path: "/expenses",
        component: () =>
          import(
            /* webpackChunkName: "expenses" */ "../views/expenses/Expenses.vue"
          ),
      },
      {
        path: "/expenses/create",
        component: () =>
          import(
            /* webpackChunkName: "expenses-create" */ "../views/expenses/CreateExpense.vue"
          ),
      },
      {
        path: "/expenses/:jnNo",
        component: () =>
          import(
            /* webpackChunkName: "expense-view" */ "../views/expenses/ExpenseView.vue"
          ),
      },
      {
        path: "/expenses/:jnNo/edit",
        component: () =>
          import(
            /* webpackChunkName: "expense-edit" */ "../views/expenses/EditExpense.vue"
          ),
      },
      {
        path: "/expense-heads",
        component: () =>
          import(
            /* webpackChunkName: "expense-heads" */ "../views/expenses/ExpenseHeads.vue"
          ),
      },
      {
        path: "/expense-heads/create",
        component: () =>
          import(
            /* webpackChunkName: "expense-heads-create" */ "../views/expenses/CreateExpenseHead.vue"
          ),
      },
      {
        path: "/settings/users",
        component: () =>
          import(
            /* webpackChunkName: "settings-users" */ "../views/users/Users.vue"
          ),
      },
      {
        path: "/settings/users/create",
        component: () =>
          import(
            /* webpackChunkName: "settings-users-create" */ "../views/users/CreateUser.vue"
          ),
      },
      {
        path: "/sku",
        component: () =>
          import(
            /* webpackChunkName: "SkuView" */ "../views/stock/sku/SkuView"
          ),
      },
      {
        path: "/sku/create",
        component: () =>
          import(
            /* webpackChunkName: "Sku-create" */ "../views/stock/sku/CreateSku"
          ),
      },
      {
        path: "/sku/:id/edit",
        component: () =>
          import(
            /* webpackChunkName: "Sku-edit" */ "../views/stock/sku/EditSku"
          ),
      },

      {
        path: "/category",
        component: () =>
          import(
            /* webpackChunkName: "Category" */ "../views/stock/category/Category"
          ),
      },
      {
        path: "/category/create",
        component: () =>
          import(
            /* webpackChunkName: "Category-create" */ "../views/stock/category/CreateCategory"
          ),
      },
      {
        path: "/category/:id/edit",
        component: () =>
          import(
            /* webpackChunkName: "Category-edit" */ "../views/stock/category/EditCategory"
          ),
      },

      {
        path: "/products",
        component: () =>
          import(
            /* webpackChunkName: "products" */ "../views/stock/product/Product"
          ),
      },
      {
        path: "/product/create",
        component: () =>
          import(
            /* webpackChunkName: "product-create" */ "../views/stock/product/CreateProduct"
          ),
      },
      {
        path: "/product/:id/edit",
        component: () =>
          import(
            /* webpackChunkName: "product-edit" */ "../views/stock/product/EditProduct"
          ),
      },
      {
        path: "/gl/account/",
        component: () =>
          import(
            /* webpackChunkName: "gl-account" */ "../views/gl/account/Index.vue"
          ),
      },
      {
        path: "/gl/account/create",
        component: () =>
          import(
            /* webpackChunkName: "gl-account-create" */ "../views/gl/account/Create.vue"
          ),
      },
      {
        path: "/gl/account/:id/edit",
        component: () =>
          import(
            /* webpackChunkName: "settings-users-create" */ "../views/gl/account/Edit.vue"
          ),
      },
      {
        path: "/gl/account/classes",
        component: () =>
          import(
            /* webpackChunkName: "settings-users-create" */ "../views/gl/Classes/Index.vue"
          ),
      },
      {
        path: "/gl/account/classes/create",
        component: () =>
          import(
            /* webpackChunkName: "settings-users-create" */ "../views/gl/Classes/Create.vue"
          ),
      },
      {
        path: "/gl/account/classes/:id/edit",
        component: () =>
          import(
            /* webpackChunkName: "settings-users-create" */ "../views/gl/Classes/Edit.vue"
          ),
      },
      {
        path: "/gl/account/group",
        component: () =>
          import(
            /* webpackChunkName: "settings-users-create" */ "../views/gl/group/Index.vue"
          ),
      },
      {
        path: "/gl/account/group/create",
        component: () =>
          import(
            /* webpackChunkName: "settings-users-create" */ "../views/gl/group/Create.vue"
          ),
      },
      {
        path: "/gl/account/group/:id/edit",
        component: () =>
          import(
            /* webpackChunkName: "settings-users-create" */ "../views/gl/group/Edit.vue"
          ),
      },
      {
        path: "/gl/account/dimensions",
        component: () =>
          import(
            /* webpackChunkName: "settings-users-create" */ "../views/gl/dimension/Index.vue"
          ),
      },
      {
        path: "/gl/account/dimensions/create",
        component: () =>
          import(
            /* webpackChunkName: "settings-users-create" */ "../views/gl/dimension/Create.vue"
          ),
      },
      {
        path: "/gl/account/dimensions/:id/edit",
        component: () =>
          import(
            /* webpackChunkName: "settings-users-create" */ "../views/gl/dimension/Edit.vue"
          ),
      },
      {
        path: "/customers",
        component: () =>
          import(
            /* webpackChunkName: "customers" */ "../views/customer/Index.vue"
          ),
      },
      {
        path: "/customer/create",
        component: () =>
          import(
            /* webpackChunkName: "customer-create" */ "../views/customer/Create.vue"
          ),
      },
      {
        path: "/customer/:id/edit",
        component: () =>
          import(
            /* webpackChunkName: "customer-create" */ "../views/customer/Edit.vue"
          ),
      },
      {
        path: "/customer/:customerId/branch",
        component: () =>
          import(
            /* webpackChunkName: "customer-branches" */ "../views/customer/branch/Index.vue"
          ),
      },
      {
        path: "/customer/:customerId/branch/create",
        component: () =>
          import(
            /* webpackChunkName: "customer-branches-create" */ "../views/customer/branch/Create.vue"
          ),
      },
      {
        path: "/customer/:customerId/branch/:id/edit",
        component: () =>
          import(
            /* webpackChunkName: "customer-branches-create" */ "../views/customer/branch/Edit.vue"
          ),
      },
      {
        path: "/gl/account/journal",
        component: () =>
          import(
            /* webpackChunkName: "gl-journal-index" */ "../views/gl/journal/Index.vue"
          ),
      },
      {
        path: "/gl/account/journal/create",
        component: () =>
          import(
            /* webpackChunkName: "gl-journal-create" */ "../views/gl/journal/Create.vue"
          ),
      },
      {
        path: "/gl/account/journal/:id/details",
        component: () =>
          import(
            /* webpackChunkName: "gl-journal-details" */ "../views/gl/journal/JournalDetails.vue"
          ),
      },
      {
        path: "/gl/account/journal/:id/edit",
        component: () =>
          import(
            /* webpackChunkName: "gl-journal-edit" */ "../views/gl/journal/Edit.vue"
          ),
      },
      {
        path: "/gl/account/gl-inquires",
        component: () =>
          import(
            /* webpackChunkName: "gl-inquires-index" */ "../views/gl/inquires/Index.vue"
          ),
      },
      {
        path: "/gl/account/bank-reconcile",
        component: () =>
          import(
            /* webpackChunkName: "gl-bank-reconcile" */ "../views/gl/inquires/BankReconcile.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.auth) &&
    !store.getters["auth/isLoggedIn"]
  ) {
    next("/login");
    return;
  }
  next();
});

export default router;
