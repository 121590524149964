<!-- <template>
  <nav>
    <el-button color="#626aef" :dark="true" plain>Plain</el-button>

    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>
  <router-view />
</template> -->

<template>
  <router-view />
</template>

<style lang="scss">
.error_msg {
  color: #f00;
  display: block;
}

.el-input,
.el-select {
  &.custom_error {
    .el-input__wrapper {
      border: 1px solid #f00;
    }
  }
}
.margin-top-10 {
  margin-top: 10px;
}
.padding-top-10 {
  padding-top: 10px;
}
.c_label {
  display: block;
  font-size: 14px;
  margin-bottom: 4px;
  line-height: 22px;
  color: #616266;
}
.m-0 {
  margin: 0;
}
.custom_form {
  padding: 15px;
  background: #f7f7f7;
  display: block;
  .el-form-item {
    margin: 0;
    display: block;
    .el-form-item__label {
      display: block;
    }

    .el-select {
      display: block;
      width: 100%;
    }
    .filter-button {
      max-width: 200px;
      // margin-left: auto;
    }
  }
  .button_area {
    margin-top: 15px;
    border-top: 1px solid #d1d1d1;
    padding-top: 15px;
  }
}
</style>
